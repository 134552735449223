import React from 'react'
import "../scss/Service.scss"
import { Link } from 'react-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile } from '@fortawesome/free-solid-svg-icons';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
import { faEthernet } from '@fortawesome/free-solid-svg-icons';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from "react-i18next";

function Service() {
  const { t } = useTranslation();
 
  return (
    <div className='service' id='service'>
        <div className='serviceContainer'>
            <h3 className='subTitle' data-aos="zoom-in"  data-aos-delay="600">{t("BEST FEATURES")}</h3>
            <h2 className='title' data-aos="zoom-in" data-aos-delay="800">{t("Our Service.")}</h2>
            <div className='row'>
                {/*
                <div className='card card1 col-xs-6 col-sm-6 col-md-3' data-aos="fade-up"  data-aos-delay="800" data-aos-duration="2400" >
                    <div className='feature' >
                        <h3>{t("See Our")}<br/>{t("Collections")}</h3>
                        <button className="shrink-border">
                            <Link to="appCollectionSlider" spy="true" smooth="true" offset={50} duration={500}>
                            {t("Go to Collections")}</Link>
                        </button>
                        
                    </div>
                    <div className='service-overlay'></div>
                </div> 
                */}
                <div className='card card2 col-xs-6 col-sm-6 col-md-3 font' data-aos="fade-up" data-aos-delay="900" data-aos-duration="2600">
                    <div className='icon'>
                        <FontAwesomeIcon icon={ faMobile } className='icon'/>
                    </div>
                    <h3>{t("Apps")}<br/>{t("Android / iOS")}</h3>
                    <p>
                    {t("service-apps")}
                    </p>
                    <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                </div>

                <div className='card card3 col-xs-6 col-sm-6 col-md-3 font' data-aos="fade-up"  data-aos-delay="1000" data-aos-duration="2800">
                    <div className='icon'>
                        <FontAwesomeIcon icon= { faLaptop } className='icon'/>
                    </div>
                    <h3>{t("SPA Website")}</h3>
                    <p>
                    {t("service-website")}
                    </p>
                    <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                </div>

                <div className='card card4 col-xs-6 col-sm-6 col-md-3 font'  data-aos="fade-up"  data-aos-delay="1100" data-aos-duration="3000">
                    <div className='icon'> 
                        <FontAwesomeIcon icon={ faEthernet } className='icon'/>
                    </div>
                    <h3>IoT</h3>
                    <p>
                    {t("service-IoT")}
                    </p>
                    <p>{t("service-IoT2")}</p>
                    <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                </div>

                <div className='card card5 col-xs-6 col-sm-6 col-md-3 font' data-aos="fade-up" data-aos-delay="900" data-aos-duration="2600">
                    <div className='icon'>
                        <FontAwesomeIcon icon={ faMicrochip } className='icon'/>
                    </div>
                    <h3>{t("AI")}</h3>
                    <p>
                    {t("service-AI")}
                    </p>
                    <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Service