import React from 'react'
import "../scss/Footer.scss"

//import Logo from "../imgs/techno-logo.png"
import Logo from "../imgs/technopressodark.png"


function Footer() {
  return (
    <footer>
        <div className="container" data-aos="zoom-out-up" data-aso- offset="0">
            <div className="footerInner" data-aos="zoom-out-up" data-aso- offset="0">
                <div className="footerImg">
                    <a href="#">
                        <img src={Logo} alt=""/>
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="col col-md-4">
                    <ul>
                        <li><nuxt-link v-scroll-to="'#about'" to>About</nuxt-link></li>
                        <li><nuxt-link v-scroll-to="'#collections'" to>Collections</nuxt-link></li>
                        <li><nuxt-link to="/contact">Contact</nuxt-link></li>
                        <li><a href="https://blog.technopresso.com" target="_blank" >Blog</a></li>
                    </ul>
                </div>

                <div className="col col-md-4">
                    <ul>
                        <li><a href="https://www.instagram.com/technopressogroup/" target="_blank" >Instagram</a></li>
                        <li><a href="https://www.facebook.com/technopressogroup" target="_blank" >Facebook</a></li>
                        <li><a href="https://twitter.com/technopresso" target="_blank" >Twitter</a></li>
                    </ul>
                </div>

                <div className="col col-md-4">
                    <ul>
                        <li><a href="https://github.com/technopresso" target="_blank" >Github</a></li>
                        <li><nuxt-link to="/contact">Gmail</nuxt-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer