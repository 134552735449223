import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../scss/AppCollectionSlider.scss';

// import required modules
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import { useTranslation } from "react-i18next";

function AppCollectionSlider() {

  const { t } = useTranslation();

  return (
    <div className='AppCollectionSlider' id='appCollectionSlider'>
        <div className='titles'>
            <h3 className='subTitle' data-aos="zoom-in" data-aos-delay="400">{t("The Best Apps at Your Fingertips")}</h3>
            <h2 className='title' data-aos="zoom-in" data-aos-delay="600">{t("App Collections.")}</h2>
       </div>
    <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        breakpoints={{
          // When window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // When window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          // More breakpoints as needed
        }}
        loop={true}
        initialSlide={1}
        pagination={false}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper">

        <SwiperSlide>
            <div class="team-box BibooTV">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.bibootv">{/*Bibootv url here*/}
                        {t("BibooTV")}
                        </a>
                    </h2>
                    <div className='description'>
                    <p>
                        {t("BibooTV description1")}
                    </p>
                    <p>
                    {t("BibooTV description2")}
                    </p>
                    <p>
                        <a target="_blank" href="https://play.google.com/store/apps/datasafety?id=com.technopresso.bibootv&pli=1">{/*BibooTV url here*/}
                        {t("Check this app")}
                        </a>
                    </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div class="team-box Dsprintly">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href="https://apps.apple.com/ph/app/dsprintly/id6470173764">{/*Dsprintly url here*/}
                        {t("Dsprintly")}
                        </a>
                    </h2>
                    <div className='description'>

                    <p>
                    {t("Dsprintly description1")}
                    </p>
                    <p>
                    {t("Dsprintly description2")}
                    </p>
                    <p>
                        <a target="_blank" href="https://apps.apple.com/ph/app/dsprintly/id6470173764">{/*Dsprintly url here*/}
                        {t("Check this app")}
                        </a>
                    </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div class="team-box Dsuit">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.dsuit">
                        {t("Dsuit")}
                        </a>
                   </h2>
                    <div className='description'>
                        <p>
                        {t("Dsuit description")}
                        </p>
                        <p>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.dsuit">
                            {t("Check this app")}
                            </a>
                        </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div class="team-box Deliveroo">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.deliverooclone">
                        {t("Deliveroo Clone")}
                        </a>
                    </h2>
                    <div className='description'>
                        <p>{t("Deliveroo description")}</p>
                        <p>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.deliverooclone">
                            {t("Check this app")}
                            </a>
                        </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div class="team-box JolieStores">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href= "https://play.google.com/store/apps/details?id=com.technopresso.joliestore">
                        {t("Jolie Stores")}
                        </a>
                    </h2>

                    <div className='description'>
                    <p>
                    {t("Jolie description")}
                    </p>
                    <p>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.joliestore">
                        Check This App
                        </a>
                    </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div class="team-box Twicher">
                <div class="team-name">
                    <center>
                        <div class="team-content">
                            <h2>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.twicher&hl=en&gl=US"> {/*Twicher url here*/}
                                {t("Twicher")}
                                </a>
                           </h2>
                            <div className='description'>
                                <p>
                                {t("Twicher description")}
                                </p>
                                <p>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.twicher&hl=en&gl=US"> {/*Twicher url here*/}
                                    {t("Check this app")}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default AppCollectionSlider;